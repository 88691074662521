import { API_GRAPH_PAYX, GRAPH_API_BUNNY_MINING } from 'config/constants/endpoints'
import { GraphQLClient } from 'graphql-request'

// Extra headers
// Mostly for dev environment
// No production env check since production preview might also need them
export const getGQLHeaders = (endpoint: string) => {
  if (endpoint === API_GRAPH_PAYX) {
    return {
      'X-Sf':
        process.env.NEXT_PUBLIC_SF_HEADER ||
        // hack for inject CI secret on window
        (typeof window !== 'undefined' &&
          // @ts-ignore
          window.sfHeader),
    }
  }
  return undefined
}

export const infoClient = new GraphQLClient(API_GRAPH_PAYX, {
  headers: {},
})

// new
export const graphqlUniFinance = new GraphQLClient(API_GRAPH_PAYX)
export const bunnyMiningServerClient = new GraphQLClient(GRAPH_API_BUNNY_MINING)
