import { ChainId } from '@pancakeswap/sdk'
import { Table } from 'antd'
import FormatNumberSmall from 'components/FormatNumberSmall'
import { formatCode } from 'helpers/CommonHelper'
import { useGetBuyBones } from 'hooks/graphQl/useGetBuyBones'
import useGetBeanRewards from 'hooks/useGetBeanRewards'
import moment from 'moment-timezone'
import styled from 'styled-components'
import { addCommasToNumber, getBscScanLink } from 'utils'
import { Link } from 'widgets'

const Wrapper = styled.div`
  padding: 20px 0px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 20px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 20px 44px;
  }

  .ant-table {
    background-color: transparent;

    thead > tr {
      > th {
        border-top: 1px solid #8d8d8d;
        border-bottom: 1px solid #8d8d8d;
        background-color: transparent;
      }

      &:first-child {
        th {
          &:first-child {
            border-left: 1px solid #8d8d8d;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
          }

          &:last-child {
            border-right: 1px solid #8d8d8d;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
          }
        }
      }

      .ant-table-cell {
        color: #bfbfbf;
        font-size: 14px;
        line-height: 150%;
        font-style: normal;
        font-weight: 600;

        ${({ theme }) => theme.mediaQueries.lg} {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    tbody > tr {
      > td {
        border-bottom: none;
      }

      &.ant-table-row:hover > td {
        background-color: rgba(255, 255, 255, 0.025);
      }

      .ant-table-cell {
        color: #fff;
        font-size: 14px;
        line-height: 150%;
        font-style: normal;
        font-weight: 500;

        ${({ theme }) => theme.mediaQueries.lg} {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
`

const Reward = ({ address }) => {
  const { data: beanRewards } = useGetBeanRewards(address)

  return <> {beanRewards ? <FormatNumberSmall number={+beanRewards / 1e18} /> : 0} BNB</>
}

const Mining = () => {
  const { loading, data } = useGetBuyBones()

  const columns = [
    {
      title: 'Date',
      width: '20%',
      dataIndex: 'blockTimestamp',
      render: (date) => moment(date * 1000).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Address',
      dataIndex: 'userAddress',
      render: (address) => (
        <Link href={getBscScanLink(address, 'address', ChainId.MAINNET)}>{formatCode(address, 5, 5)}</Link>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (amount) => `${addCommasToNumber(+amount / 1e18, 3)} BNB`,
    },
    {
      title: 'Reward',
      dataIndex: 'userAddress',
      render: (address) => <Reward address={address} />,
    },
    {
      title: 'Hash',
      dataIndex: 'transactionHash',
      render: (txh) => <Link href={getBscScanLink(txh, 'transaction', ChainId.MAINNET)}>{formatCode(txh, 5, 5)}</Link>,
    },
  ]

  return (
    <Wrapper>
      <Table
        loading={loading}
        dataSource={data}
        columns={columns}
        rowKey={(ojb) => ojb.id}
        pagination={
          !(data?.length <= 10)
            ? {
                showSizeChanger: false,
              }
            : false
        }
        scroll={{ x: 800 }}
      />
    </Wrapper>
  )
}

export default Mining
