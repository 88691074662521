import { gql } from 'graphql-request'
import { useCallback, useState } from 'react'
import useSWR from 'swr'
import { SLOW_INTERVAL } from '../../config/constants/index'
import { bunnyMiningServerClient } from './index'

const QUERY = gql`
  query MyQuery($first: Int, $skip: Int) {
    buyBones_collection(
      first: $first
      skip: $skip
      orderBy: blockTimestamp
      orderDirection: desc
      where: { blockTimestamp_gt: 1719734400 }
    ) {
      id
      amount
      blockTimestamp
      transactionHash
      userAddress
    }
  }
`

export const getBuyBones = async (): Promise<{ result?: any; error: boolean }> => {
  try {
    const first = 1000
    let skip = 0
    let data: any | undefined

    while (true) {
      const dataRs = await bunnyMiningServerClient.request(QUERY, { first: Number(first), skip: Number(skip) })

      if (!data) {
        data = {
          buyBonesList: [],
        }
      }

      if (!dataRs || !dataRs.buyBones_collection || dataRs.buyBones_collection.length === 0) {
        break
      }

      data.buyBonesList.push(...dataRs.buyBones_collection)

      skip += 1000
    }

    return {
      result: data,
      error: false,
    }
  } catch (error) {
    console.error('getBuyBones', error)
    return { error: true }
  }
}

export const useGetBuyBones = () => {
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(async () => {
    setLoading(true)
    try {
      const { result } = await getBuyBones()
      setLoading(false)

      if (result?.buyBonesList) {
        return result?.buyBonesList
      }
    } catch (error) {
      setLoading(false)
      console.log('🚀 ~ fetchData ~ useGetBuyBones:', error)
    }
  }, [])

  const { data, mutate } = useSWR(
    ['getBuyBones'],
    () => {
      return fetchData()
    },
    {
      revalidateOnFocus: false,
    },
  )

  return { data, refresh: mutate, loading }
}
